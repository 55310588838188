import metadata from 'src/scripts/metadata.json';
import {API, clientDomain} from "./config";


export const APP_VERSION = `${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision} ${metadata.buildTag}`;

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
    LIGHT: 'LIGHT',
    ONE_DARK: 'ONE_DARK',
    UNICORN: 'UNICORN'
};

export const REGEXPS = {
    PWD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(.*){8,}$/
};

export const GENRE_OPTIONS = [
    {
        id: 'F',
        name: 'Femmina'
    },
    {
        id: 'M',
        name: 'Maschio'
    }
];


export const ENABLED_OPTIONS = [
    {
        id: 1,
        name: 'Abilitati'
    },
    {
        id: 0,
        name: 'Non abilitati'
    }
];

//console.log("clientDomain", clientDomain);

console.info("clientDomain.smartvillage", clientDomain.smartvillage)

export const DOMAIN_PROPERTIES = {
    [clientDomain.mosaico]: {
        title: 'Mosaico',
        logo: '/static/logo/mosaico.png',
        homeLogo: '/static/home/mosaico.png',
        projectDescription: 'Mosaico Project',
        projectTitle: 'Mosaico è un progetto della Regione Marche per l\'inclusione e la prevenzione sanitaria delle persone più fragili. Il software si occupa dell\'inserimento dei dati delle visite, e del monitoraggio dei parametri dei pazienti.',
        barColor: '#e7d115',
        apiUrl: API.mosaico
    },
    [clientDomain.smartvillage]: {
        title: 'Smartvillage',
        logo: '/static/logo/smartvillage.png',
        homeLogo: '/static/home/smartvillage.png',
        projectDescription: 'Smartvillage Project',
        projectTitle: 'Smartvillage è un progetto della Regione Marche la prevenzione degli infortuni domestici delle persone più fragili. Il software si occupa dell\'inserimento dei dati delle visite, e del monitoraggio dei parametri dei pazienti.',
        barColor: '#d11d0a',
        apiUrl: API.smartvillage
    }
}


export const TEXT = {
    IT: {
        ROLEID: {
            minLength: 'Ruolo richiesto',
            StringTooShort: "Ruolo richiesto"
        },
        NAME: {
            minLength: 'Nome richiesto.',
            TypeNotValid: 'Nome non valido',
            RegexNotValid: 'Nome non valido',
            GenericError: 'Errore generico',
            StringTooShort: 'Nome richiesto.',
        },
        GENRES: {
            GenderCompulsory: 'Sesso obbligatorio'
        },
        SURNAME: {
            minLength: 'Cognome richiesto.',
            StringTooShort: 'Cognome richiesto.',
            TypeNotValid: 'Cognome non valido',
            RegexNotValid: 'Cognome non valido',
            GenericError: 'Errore generico'
        },
        CITYID: {
            StringTooShort: 'Città richiesta.'
        },
        LOCATIONOFBIRTHCITYID: {
            StringTooShort: 'Città richiesta.'
        },
        LOCATIONOFRESIDENCECITYID: {
            StringTooShort: 'Città richiesta.'
        },
        GENREID: {
            minlength: 'Sesso richiesto.',
            enum: 'Sesso richiesto'
        },
        FISCALCODE: {
            minLength: 'Codice fiscale troppo corto.',
            StringTooShort: 'Codice fiscale troppo corto.',
            TypeNotValid: 'Codice fiscale non valido',
            RegexNotValid: 'Codice fiscale non valido',
            GenericError: 'Errore generico',
            FiscalCodeExists: 'Codice fiscale esistente'
        },
        ACCOUNTPWD: {
            pattern: 'Password non valida',
            StringTooShort: 'Password troppo corta.',
            TypeNotValid: 'Tipo non valido',
            RegexNotValid: 'Password non valida',
            GenericError: 'Errore generico'
        },
        
        ACCOUNTEMAIL: {
            format: 'Email non valida',
            pattern: 'Email non valida',
            StringTooShort: 'Email richiesta.',
            TypeNotValid: 'Tipo non valido',
            RegexNotValid: 'Campo non valido',
            EmailNotValid: 'Email non valida ',
            EmailExists: 'Email esistente',
        },
    
        DAYS: {
            WeeklyDayNotSet: 'Giorno della settimana non selezionato'
        },

        DATE: {
            DateNotValid: 'Data non valida'
        },
        SEATS: {
            NumberNotValid: 'Numero non valido'
        },
        BIOMETRICDATAHEIGHT: {
            TypeNotValid: 'Altezza non valida',

        },
        BIOMETRICDATAWEIGHT: {
            TypeNotValid: 'Peso non valido',
        },
        BIOMETRICDATAWAIST: {
            TypeNotValid: 'Circonferenza non valida',
        },
        EMAIL: {
            PhoneMissing: 'Nessun numero per questo account',
            AccountNotExists: 'Account non esiste',
            PasswordNotMatch: 'Password non valida',
            AccountNotEnabled: 'Account non abilitato'
        },
        PATIENTID: {
            StringTooShort: 'Paziente obbligatorio'
        },
        STATUSID: {
            StringTooShort: 'Stato obbligatorio'
        },
        TEXT: {
            StringTooShort: 'Testo domanda obbligatorio'
        },
        CATEGORYID: {
            NotValidCategory: 'Categoria obbligatoria',
            StringTooShort: 'Categoria obbligatoria'
        },
        SPECID: {
            StringTooShort: 'Specializzazione obbligatoria'
        },
        SCREENINGID: {
            NotValid: 'Screening obbligatorio',
        },
        UNITID: {
            StringTooShort: 'Unità obbligatoria'
        },
        MODEID: {
            NotValidMode: 'Modalità obbligatoria'
        },
        DOCTORID: {
            StringTooShort: 'Dottore obbligatorio',
            TypeNotValid: 'Dottore richiesto'
        },
        SERVICEID: {
            StringTooShort: 'Campo obbligatorio',
        },
        DEADLINE: {
            StringTooShort: 'Campo obbligatorio',
        },
        DAYOFBIRTH:{
          InvalidDate: 'Data non valida'
        },
     
        PWD: {
            PwdNotValid: 'Password non valida',
            StringTooShort: 'Password richiesta.'
        },
    }
};


export const ALERTVALUES = [
    {
        id: 'GTE',
        text: 'Maggiore o uguale a'
    },
    {
        id: 'LTE',
        text: 'Minore o uguale a'
    },
    {
        id: 'EQ',
        text: 'Uguale a'
    },
    {
        id: 'BTW',
        text: 'Tra'
    }
];

export const VARIABLEDATA = [
    {
        id: '_Fall',
        text: 'Caduta'
    },
    {
        id: '_Step',
        text: 'Passi'
    },
    {
        id: '_MinBloodPressure',
        text: 'Pressione sanguinia minima'
    },
    {
        id: '_MaxBloodPressure',
        text: 'Pressione sanguinia massima'
    },
    {
        id: '_OxygenSaturation',
        text: 'Saturazione'
    },
    {
        id: '_ECG',
        text: 'ECG'
    },
    {
        id: '_LBM',
        text: 'LBM'
    },
    {
        id: '_FBM',
        text: 'FBM'
    },
    {
        id: '_Weight',
        text: 'Peso'
    },
    {
        id: '_BMI',
        text: 'BMI'
    },
    {
        id: '_ManualSOS',
        text: 'SOS Manuale'
    },
    {
        id: '_HeartRate',
        text: 'Frequenza cardiaca'
    },
    {
        id: '_HeartRateAW',
        text: 'Frequenza cardiaca Apple Watch'
    }
];

export const REFERENTS = [
    {
        id: 'family-doctor',
        text: 'Dottore di famiglia'
    },
    {
        id: 'referent',
        text: 'Referente'
    }
];


export const paths = {
    assignedRequests: '/app/assigned/requests',
    requests: '/app/requests',
    operatorDashboard: '/app/operator/dashboard',
    assistantDashboard: '/app/assistant/falls',
    alert: '/app/admin/alerts',
    spec: '/app/admin/specs',
    medicalData: '/app/admin/medical-data',
    service: '/app/admin/service',
    screening: '/app/admin/screenings',
    unit: '/app/admin/units',
    category: '/app/admin/categories',
    option: '/app/admin/options',
    user: '/app/admin/users',
    question: '/app/admin/questions',
    log: '/app/admin/logs',
    riskScale: '/app/admin/risk-scales',
    patient: '/app/management/patients',
    exam: '/app/management/exams',
    hufi: '/app/management/hufi',
    dashboard: '/app/admin/dashboard'
}

export const ROLES = {
    admin: 'ADM',
    socialOperator: 'OSO',
    coordinator: 'COO',
    patient: 'PAT',
    doctor: 'MSP',
    med: 'MED',
    assistant: 'ASC'
}

export const VIEWACCESS = {
    admin: {
        management: 'admin.management'
    },
    exam: {
        visualization: 'exam.visualization',
        management: 'exam.management'
    },
    hufi: {
        visualization: 'hufi.visualization',
        management: 'hufi.management'
    },
    patient: {
        visualization: 'patient.visualization',
        management: 'patient.management'
    },
    patientRequest: {
        management: 'patientRequest.management'
    },
    ownPatientRequest: {
        management: 'ownPatientRequest.management'
    },
    fall: {
        management: 'fall.management'
    }
}
