import {paths} from "../constants";

const PAGES = {
    
    'log.list': {
        title: "Lista dei log",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Log',
                to: '/app/admin/logs',
            },
        ],
    },

    'fall.list': {
        title: "Lista delle cadute",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Cadute',
                to: '/app/assistant/falls',
            },
        ],
    },
    
    'stop.list': {
        title: "Lista degli appuntamenti",
        breadcrumbs: [
            {
                type: 'Link',
                text: 'Appuntamenti',
                to: '/app/admin/requests-stops',
            },
        ],
    },

    'stop.route': {
        title: "Percorso",
        breadcrumbs: [
            {
                type: 'Link',
                text: 'Appuntamenti',
                to: '/app/admin/requests-stops',
            },
            {
                type: 'Typography',
                text: 'Percorso'
            },
        ],
    },

    'hufi.list': {
        title: "Lista degli indici Hufi",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Hufi',
                to: '/app/management/hufi',
            },
        ],
    },
    
    'hufi.create': {
        title: "Nuovo hufi",
        button: "Carica",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Hufi',
                to: '/app/management/hufi',
            },
        ],
    },
    
    'hufi.edit': {
        title: "Nuovo hufi",
        button: "Modifica",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Hufi',
                to: '/app/management/hufi',
            },
        ],
    },

    'fall.edit': {
        title: "Caduta",
        button: "Modifica",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Cadute',
                to: paths.assistantDashboard,
            },
        ],
    },

    'service.edit': {
        title: "Servizio",
        button: "Modifica",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Servizi',
                to: '/app/management/service',
            },
        ],
    },

    'service.create': {
        title: "Nuovo servizio",
        button: "Crea",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Servizi',
                to: '/app/admin/service',
            },
        ],
    },


    'service.list': {
        title: "Lista dei servizi",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Servizi',
                to: '/app/management/service',
            },
        ],
    },

    'exam.list': {
        title: "Lista delle visite",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Visite',
                to: '/app/management/exams',
            },
        ],
    },
    
    'exam.create': {
        title: "Nuova visita",
        button: "Carica",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Visite',
                to: '/app/management/exams',
            },
        ],
    },

    'exam.edit': {
        title: "Nuova visita",
        button: "Modifica",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Visite',
                to: '/app/management/exams',
            },
        ],
    },

    'patient.list': {
        title: "Lista dei pazienti",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Pazienti',
                to: '/app/management/patients',
            },
        ],
    },

    'patient.telemedicine': {
        title: "Telemedicina",
        button: "Telemedicina",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                to: '/app/management/patients',
                text: 'Pazienti'
            },
            {
                type: 'Typography',
                text: 'Telemedicina'
            },
        ]
    },


    'patient.create': {
        title: "Nuovo paziente",
        button: "Crea paziente",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                to: '/app/management/patients',
                text: 'Pazienti'
            },
            {
                type: 'Typography',
                text: 'Crea paziente'
            },
        ]
    },

    'patient.edit': {
        button: "Modifica paziente",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                to: '/app/management/patients',
                text: 'Pazienti'
            },
            {
                type: 'Typography',
                text: 'Modifica paziente'
            },
        ]
    },

    'user.list': {
        title: "Lista degli utenti",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Utenti',
                to: '/app/admin/users',
            },
        ],
    },

    'user.edit': {
        //title: "Utente @name@",
        button: "Modifica utente",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                to: '/app/admin/users',
                text: 'Utenti'
            },
            {
                type: 'Typography',
                text: 'Modifica utente'
            },
        ]
    },

    'user.create': {
        //title: "Nuovo utente",
        button: "Crea utente",
        action: "",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                to: '/app/admin/users',
                text: 'Utenti'
            },
            {
                type: 'Typography',
                text: 'Nuovo utente'
            },
        ]
    },

    'question.list': {
        title: "Lista delle domande",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Domande',
                to: '/app/admin/questions',
            },
        ],
    },
    'question.management': {
        title: "Gestione delle visite",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            }

        ],
    },


    'question.create': {
        title: "Dettagli della domanda",
        button: "Crea",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Domande',
                to: '/app/admin/questions',
            },
        ],
    },

    'question.edit': {
        title: "Modifica domanda",
        button: "Modifica",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Domande',
                to: '/app/admin/questions',
            },
        ],
    },
    
    'houseCall.list': {
        title: "Lista dei controlli domiciliari",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Controlli domiciliari',
                to: '/app/admin/house-calls',
            },
        ],
    },
    
    'houseCall.create': {
        title: "Dettagli della domanda",
        button: "Crea",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Controlli domiciliari',
                to: '/app/management/house-calls',
            },
        ],
    },
    
    'houseCall.edit': {
        title: "Modifica domanda",
        button: "Modifica",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Controlli domiciliari',
                to: '/app/management/house-calls',
            },
        ],
    },
    
    'category.list': {
        title: "Lista delle categorie",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Categorie',
                to: '/app/admin/categories',
            },
        ],
    },
    
    'category.create': {
        title: "Dettagli della categoria",
        button: "Crea",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Categorie',
                to: '/app/admin/categories',
            },
        ],
    },
    
    'category.edit': {
        title: "Modifica categoria",
        button: "Modifica",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Categorie',
                to: '/app/admin/categories',
            },
        ],
    },
    
    'option.list': {
        title: "Lista delle opzioni",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Opzioni',
                to: '/app/admin/options',
            },
        ],
    },
    
    'option.create': {
        title: "Dettagli dell'opzione",
        button: "Crea",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Opzioni',
                to: '/app/admin/options',
            },
        ],
    },
    
    'option.edit': {
        title: "Modifica opzione",
        button: "Modifica",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Opzioni',
                to: '/app/admin/options',
            },
        ],
    },
    'unit.list': {
        title: "Lista delle unità",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Unità',
                to: '/app/admin/units',
            },
        ],
    },
    
    'unit.create': {
        title: "Dettagli dell'unità",
        button: "Crea",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Unità',
                to: '/app/admin/units',
            },
        ],
    },
    'unit.edit': {
        title: "Modifica unità",
        button: "Modifica",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Unità',
                to: '/app/admin/units',
            },
        ],
    },
    
    'riskScale.list': {
        title: "Lista delle scale di rischio",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Scale di rischio',
                to: '/app/admin/risk-scales',
            },
        ],
    },
    'riskScale.create': {
        title: "Dettagli della scala di rischio",
        button: "Crea",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Scala di rischio',
                to: '/app/admin/risk-scales',
            },
        ],
    },
    
    'riskScale.edit': {
        title: "Modifica scala di rischio",
        button: "Modifica",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Unità',
                to: '/app/admin/risk-scales',
            },
        ],
    },
    'request.list': {
        title: "Lista delle richieste",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            }
        ],
    },
    'assignedRequest.list': {
        title: "Lista delle attività assegnate a me",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            }
        ],
    },
    'request.edit': {
        title: "Gestisci richiesta",
        button: "Salva e genera attività",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Richieste',
                to: '/app/requests',
            },
        ],
    },
    'assignedRequest.edit': {
        title: "Gestisci richiesta",
        button: "Salva",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Richieste',
                to: '/app/assigned/requests',
            },
        ],
    },
    'requests.calendar': {
        title: "Lista richieste",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Attività',
                to: '/app/assigned/requests',
            },
        ],
    },
    'screening.list': {
        title: "Lista degli screening",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Screening',
                to: '/app/admin/screenings',
            },
        ],
    },
    
    'screening.edit': {
        title: "Modifica screening",
        button: "Modifica",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Screening',
                to: '/app/admin/screenings',
            },
        ],
    },
    'screening.create': {
        title: "Nuovo screening",
        button: "Crea",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Screening',
                to: '/app/admin/screenings',
            },
        ],
    },
    'screening.calendar': {
        title: "Lista screening",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Screening',
                to: '/app/admin/screenings',
            },
        ],
    },
    'medicalData.list': {
        title: "Lista dei dati medici",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Dati medici',
                to: '/app/admin/medical-data',
            },
        ],
    },
    'medicalData.edit': {
        title: "Modifica dato medico",
        button: "Modifica",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Dati medici',
                to: '/app/admin/medical-data',
            },
        ],
    },
    'medicalData.create': {
        title: "Nuovo dato medico",
        button: "Crea",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Dati medici',
                to: '/app/admin/medical-data',
            },
        ],
    },
    'spec.list': {
        title: "Lista delle specializzazioni",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Specializzazioni',
                to: '/app/admin/specs',
            },
        ],
    },
    'spec.edit': {
        title: "Modifica spec",
        button: "Modifica",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Specializzazioni',
                to: '/app/admin/specs',
            },
        ],
    },
    'spec.create': {
        title: "Nuova specializzazione",
        button: "Crea",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Specializzazione',
                to: '/app/admin/specs',
            },
        ],
    },
    'alert.list': {
        title: "Lista degli alert",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Alert',
                to: '/app/admin/alerts',
            },
        ],
    },
    
    'upload.list': {
        title: "Lista degli upload",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Alert',
                to: '/app/admin/alerts',
            },
        ],
    },
    
    'alert.edit': {
        title: "Modifica alert",
        button: "Modifica",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Alert',
                to: '/app/admin/alerts',
            },
        ],
    },
    'alert.create': {
        title: "Nuovo alert",
        button: "Crea",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Alert',
                to: '/app/admin/alerts',
            },
        ],
    },
    
    'manualData': {
        title: "Inserimento manuale di dati device",
        button: "Invia",
        breadcrumbs: [
            {
                type: 'Typography',
                text: 'Anagrafiche'
            },
            {
                type: 'Link',
                text: 'Inserimento manuale',
                to: '/app/admin/manual-insert',
            },
        ],
    },
    

}

const getPage = ( pageId ) => {

    var page = PAGES[ pageId ];

    if ( page ) {

        return page;

    } else {

        throw "Page not found: [" + pageId + "]";

    }

}

export default getPage;